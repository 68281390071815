import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import Section from '../Section'
import HeaderTriangle1 from '../../static/img/about_us/HeaderTriangle1.svg'
import HeaderTriangle2 from '../../static/img/about_us/HeaderTriangle2.svg'
import HeaderTriangleBg from '../../static/img/about_us/HeaderTriangleBg.svg'
import HeaderTriangle1Mobile from '../../static/img/about_us/HeaderTriangle1Mobile.svg'
import HeaderTriangle2Mobile from '../../static/img/about_us/HeaderTriangle2Mobile.svg'
import HeaderTriangleBgMobile from '../../static/img/about_us/HeaderTriangleBgMobile.svg'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import { aboutUsData } from '../../hooks/about-us-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #332843;
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 52px;
  color: #ffffff;
  align-self: center;
  text-align: center;
  z-index: 1;
  @media (min-width: 512px) {
    font-size: 48px;
    line-height: 124.84%;
  }
`

const Header = props => {
  const {
    acf: { top_title },
  } = aboutUsData(props.pageContext.lang)
  return (
    <>
      <Section background={<Bg />} pt={73}>
        <Flex px={[15, 200]} pt={[70, 104]} pb={[99, 133]}>
          <Title>{top_title}</Title>
        </Flex>
        <DesktopFlex>
          <HeaderTriangle1
            style={{ position: 'absolute', top: '15%', left: '10%' }}
          />
          <HeaderTriangleBg
            style={{ position: 'absolute', top: 20, left: '60%' }}
          />
        </DesktopFlex>
        <MobileFlex>
          <HeaderTriangleBgMobile
            style={{ position: 'absolute', top: 40, left: '20%' }}
          />
        </MobileFlex>
      </Section>
      <DesktopFlex>
        <HeaderTriangle2
          style={{ position: 'absolute', top: 220, right: '5%' }}
        />
      </DesktopFlex>
      <MobileFlex>
        <HeaderTriangle1Mobile
          style={{ position: 'absolute', top: 380, left: '4%' }}
        />
        <HeaderTriangle2Mobile
          style={{ position: 'absolute', top: 360, right: 0 }}
        />
      </MobileFlex>
    </>
  )
}

export default Header
