import { useStaticQuery, graphql } from 'gatsby'

export const aboutUsData = lang => {
  const { page } = useStaticQuery(
    graphql`
      query {
        page: allWordpressPage(filter: { wordpress_id: { eq: 2016 } }) {
          edges {
            node {
              wordpress_id
              slug
              title
              content
              polylang_translations {
                polylang_current_lang
                acf {
                  top_title
                  goal_title
                  goal_desc
                  engage_title
                  engage_desc
                  engange_comment
                  story_title
                  story_desc
                  story_comment
                  story_image {
                    localFile {
                      childImageSharp {
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  case1_title
                  case1_desc
                  case1_comment
                  case2_title
                  case2_desc
                  case2_comment
                  case1_image {
                    localFile {
                      childImageSharp {
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  case2_image {
                    localFile {
                      childImageSharp {
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  percentage
                  percentage_text
                  percentage_desc
                  proud_title
                  proud_list {
                    link1 {
                      text
                      url
                    }
                    link2 {
                      text
                      url
                    }
                    link3 {
                      text
                      url
                    }
                    link4 {
                      text
                      url
                    }
                    link5 {
                      text
                      url
                    }
                  }
                  proud_button_title
                  join_title
                }
                yoast_meta {
                  yoast_wpseo_title
                  yoast_wpseo_metadesc
                }
              }
            }
          }
        }
      }
    `
  )
  return page.edges[0].node.polylang_translations.find(
    item => item.polylang_current_lang == lang
  )
}
