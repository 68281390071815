import React from 'react'
import Img from 'gatsby-image'
import Section from '../Section'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import JoinTriangle1 from '../../static/img/about_us/joinTriangle1.svg'
import JoinTriangle2 from '../../static/img/about_us/joinTriangle2.svg'
import JoinTriangle3 from '../../static/img/about_us/joinTriangle3.svg'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import { aboutUsData } from '../../hooks/about-us-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  background: #2E203B;
  z-index: -10;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  color: #ffffff;
`

const LogosContainer = props => (
  <Flex width={1} justifyContent="center">
    {props.children}
  </Flex>
)

const Join = props => {
  const {
    acf: { join_title },
  } = aboutUsData(props.pageContext.lang)
  const {
    linkedin,
    linkedinMobile,
    twitter,
    twitterMobile,
    facebook,
    facebookMobile,
    github,
    githubMobile,
  } = useStaticQuery(
    graphql`
      query {
        linkedin: file(relativePath: { eq: "about_us/Linkedin.png" }) {
          childImageSharp {
            fixed(width: 120, height: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        linkedinMobile: file(
          relativePath: { eq: "about_us/LinkedinMobile.png" }
        ) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        twitter: file(relativePath: { eq: "about_us/Twitter.png" }) {
          childImageSharp {
            fixed(width: 120, height: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        twitterMobile: file(
          relativePath: { eq: "about_us/TwitterMobile.png" }
        ) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        facebook: file(relativePath: { eq: "about_us/Facebook.png" }) {
          childImageSharp {
            fixed(width: 120, height: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        facebookMobile: file(
          relativePath: { eq: "about_us/FacebookMobile.png" }
        ) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        github: file(relativePath: { eq: "about_us/Github.png" }) {
          childImageSharp {
            fixed(width: 120, height: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        githubMobile: file(relativePath: { eq: "about_us/GithubMobile.png" }) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const newLogos = [
    {
      logo: linkedin,
      mobileLogo: linkedinMobile,
      href: 'https://www.linkedin.com/company/hubtype',
    },
    {
      logo: twitter,
      mobileLogo: twitterMobile,
      href: 'https://twitter.com/hubtype',
    },
    {
      logo: facebook,
      mobileLogo: facebookMobile,
      href: 'https://www.facebook.com/hubtype/',
    },
    {
      logo: github,
      mobileLogo: githubMobile,
      href: 'https://github.com/hubtype',
    },
  ]

  return (
    <Flex mb={[-110, -150]} px={[0, 0]} pb={88} flexDirection="column">
      <Section background={<Bg />} pt={[60, 80]} pb={[80, 150]}>
        <Title>{join_title}</Title>
        <LogosContainer>
          {newLogos.map((e, i) => (
            <a key={i} style={{ width: '20%', height: 'auto' }} href={e.href}>
              <Flex justifyContent="center">
                <DesktopFlex>
                  <Img fixed={e.logo.childImageSharp.fixed} />
                </DesktopFlex>
                <MobileFlex>
                  <Img fixed={e.mobileLogo.childImageSharp.fixed} />
                </MobileFlex>
              </Flex>
            </a>
          ))}
        </LogosContainer>

        <DesktopFlex>
          <JoinTriangle1
            style={{
              position: 'absolute',
              right: 0,
              top: -100,
            }}
          />
          <JoinTriangle2
            style={{
              position: 'absolute',
              left: 0,
              top: 50,
            }}
          />

          <JoinTriangle3
            style={{
              position: 'absolute',
              left: 169,
              top: 280,
              zIndex: 1,
            }}
          />
        </DesktopFlex>
        <MobileFlex>
          <JoinTriangle3
            style={{
              position: 'absolute',
              left: 175,
              top: 260,
              zIndex: 1,
            }}
          />
        </MobileFlex>
      </Section>
    </Flex>
  )
}

export default Join
