import React from 'react'
import Section from '../Section'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { aboutUsData } from '../../hooks/about-us-data'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #F2F2F2;
`

const Title = styled.h2`
  font-size: 30px;
  line-height: 40px;
  color: #333333;
  padding-right: 5px;
`

const Text = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
  margin-top: 15px;
  padding-right: 5px;
`

const SecondaryText = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #696973;
`

const WhatsappDevelopers = props => {
  const {
    acf: {
      case1_title,
      case1_desc,
      case1_comment,
      case2_title,
      case2_desc,
      case2_comment,
      case1_image,
      case2_image,
    },
  } = aboutUsData(props.pageContext.lang)
  return (
    <Section background={<Bg />} pt={[60, 80]} pb={[60, 100]}>
      <Flex px={[15, 0]} flexDirection={['column', 'row']}>
        <Flex width={[1, 1 / 2]} flexDirection="column">
          <Flex css={{ height: 55, width: 40 }}>
            <Img fixed={case1_image.localFile.childImageSharp.fixed} />
          </Flex>
          <Flex className="whatsapp-developers">
            <Title>{case1_title}</Title>
          </Flex>
          <Text
            dangerouslySetInnerHTML={{
              __html: case1_desc,
            }}
          />
          <SecondaryText
            dangerouslySetInnerHTML={{
              __html: case1_comment,
            }}
          />
        </Flex>
        <Flex width={[1, 1 / 2]} flexDirection="column">
          <Flex css={{ height: 55, width: 50 }}>
            <Img fixed={case2_image.localFile.childImageSharp.fixed} />
          </Flex>
          <Flex className="whatsapp-developers">
            <Title>{case2_title}</Title>
          </Flex>
          <Text
            dangerouslySetInnerHTML={{
              __html: case2_desc,
            }}
          />
          <SecondaryText
            dangerouslySetInnerHTML={{
              __html: case2_comment,
            }}
          />
        </Flex>
      </Flex>
    </Section>
  )
}

export default WhatsappDevelopers
