import React from 'react'
import Section from '../Section'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import DownArrow from '../../static/img/icons/downArrowRose.svg'
import ProudOfTriangleBg from '../../static/img/about_us/ProudOfTriangleBg.svg'
import { DesktopFlex } from '../breakpoints'
import { OutlineButton } from '../../components/buttons'
import { aboutUsData } from '../../hooks/about-us-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #3A2949;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
`

const Link = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #ff2b5e;
`

const ProudOf = props => {
  const {
    acf: { proud_title, proud_list, proud_button_title },
  } = aboutUsData(props.pageContext.lang)
  var listOfLinks = Object.values(proud_list)
  return (
    <>
      <Section
        background={
          <>
            <Bg>
              <DesktopFlex>
                <ProudOfTriangleBg
                  style={{ position: 'absolute', left: '60%', top: -100 }}
                />
              </DesktopFlex>
            </Bg>
          </>
        }
        pt={[60, 80]}
        pb={[60, 64]}
      >
        <Flex px={[15, 0]} flexDirection={'column'}>
          <Box width={[255, 1]}>
            <Title>{proud_title}</Title>
          </Box>

          {listOfLinks.map((item, i) => (
            <Flex key={i} width={[1, 0.6]}>
              <Link href={item.url} style={{ textDecoration: 'none' }}>
                {item.text}
              </Link>
            </Flex>
          ))}

          <OutlineButton
            color="#FF4B76"
            hoverBackground="#FF4B76"
            style={{
              background: 'transparent',
              height: '40px',
              maxWidth: '160px',
              marginTop: '32px',
              width: '160px',
              border: '6px',
            }}
          >
            {proud_button_title}
            <DownArrow style={{ marginLeft: '34px' }} />
          </OutlineButton>
        </Flex>
        <DesktopFlex />
      </Section>
    </>
  )
}

export default ProudOf
