import React from 'react'
import Section from '../Section'
import { Flex } from '@rebass/grid'
import styled from 'styled-components'

import RevolutionTriangle from '../../static/img/about_us/RevolutionTriangle.svg'
import RevolutionTriangleBg from '../../static/img/about_us/RevolutionTriangleBg.svg'
import RevolutionTriangleMobile from '../../static/img/about_us/RevolutionTriangleMobile.svg'

import { DesktopFlex, MobileFlex } from '../breakpoints'
import { aboutUsData } from '../../hooks/about-us-data'
const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #F2F2F2;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;
  color: #333333;
`

const Text = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
`

const SecondaryText = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #696973;
`

const Separator = styled(Flex)`
  width: 100%;
  height: 0px;
  border: 1px solid #e8daff;
  @media (min-width: 512px) {
    width: 100%;
    border: 1px solid #e8daff;
  }
`

const Percentage = styled.h3`
  font-weight: bold;
  font-size: 64px;
  line-height: 94px;
  letter-spacing: -0.04em;
  color: #6f16ff;
  margin-right: 9px;
  margin-bottom: 0px;
`

const PercentageTitle = styled.h4`
  font-size: 16px;
  font-weight: normal;
  line-height: 120%;
  color: #6f16ff;
  margin-top: 35px;
  width: 100%;
  text-transform: none;
  @media (min-width: 512px) {
    width: 30%;
  }
`

const PercentageSubtitle = styled.h5`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #696973;
`

const Information = props => (
  <Flex width={[1, 1 / 2]} pl={[0, 127]} pt={[0, 200]}>
    <Flex width={1} flexDirection="column">
      <Separator />
      <Flex pr={[0, 100]} py={3} flexDirection="column">
        <Flex alignItems="center">
          <Percentage>{props.percentage}</Percentage>
          <PercentageTitle>{props.text}</PercentageTitle>
        </Flex>
        <PercentageSubtitle style={{ marginTop: -15 }}>
          {props.desc}
        </PercentageSubtitle>
      </Flex>
      <Separator />
    </Flex>
  </Flex>
)

const ConversationalRevolution = props => {
  const {
    acf: {
      engage_title,
      engage_desc,
      engange_comment,
      percentage,
      percentage_text,
      percentage_desc,
    },
  } = aboutUsData(props.pageContext.lang)
  return (
    <Section background={<Bg />} pt={[60, 80]} pb={[93, 74]}>
      <Flex px={[15, 0]} flexDirection={['column', 'row']}>
        <Flex width={[1, 1 / 2]} flexDirection="column">
          <Title>{engage_title}</Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: engage_desc,
            }}
          />
          <SecondaryText
            dangerouslySetInnerHTML={{
              __html: engange_comment,
            }}
          />
        </Flex>
        <DesktopFlex>
          <RevolutionTriangle
            style={{ position: 'absolute', top: '88%', left: '40%' }}
          />
          <RevolutionTriangleBg
            style={{ position: 'absolute', left: 0, top: '60%' }}
          />
        </DesktopFlex>
        <MobileFlex>
          <RevolutionTriangleMobile
            style={{ position: 'absolute', top: '95%', right: '30%' }}
          />
        </MobileFlex>
        <Information
          percentage={percentage}
          text={percentage_text}
          desc={percentage_desc}
        />
      </Flex>
    </Section>
  )
}

export default ConversationalRevolution
