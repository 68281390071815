import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Section from '../Section'

import HubtypeHelpsTriangleBg from '../../static/img/about_us/HubtypeHelpsTriangleBg.svg'
import HubtypeHelpsTriangle from '../../static/img/about_us/HubtypeHelpsTriangle.svg'
import HubtypeHelpsTriangleMobile from '../../static/img/about_us/HubtypeHelpsTriangleMobile.svg'
import Mission from '../../static/img/about_us/Mission.svg'
import { aboutUsData } from '../../hooks/about-us-data'

import { DesktopFlex, MobileFlex } from '../breakpoints'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #6F16FF;
`

const Title = styled.h2`
  font-size: 34px;
  line-height: 45px;
  color: #ffffff;
`

const Text = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #e8daff;
`

const Figures = props => (
  <>
    <DesktopFlex>
      {props.mockup && (
        <Img
          style={{
            position: 'absolute',
            width: 335,
            height: 598,
            top: 100,
            right: '23%',
            zIndex: 1,
          }}
          fluid={props.mockup.childImageSharp.fluid}
        />
      )}

      <HubtypeHelpsTriangleBg
        style={{ position: 'absolute', top: 200, right: 0 }}
      />
      <HubtypeHelpsTriangle
        style={{ position: 'absolute', top: 360, left: '50%', zIndex: 0 }}
      />
    </DesktopFlex>
    <MobileFlex>
      <HubtypeHelpsTriangleMobile
        style={{ position: 'absolute', top: '90%', right: '5%' }}
      />
      <Mission
        style={{ position: 'absolute', top: 150, right: 0, zIndex: -1 }}
      />
    </MobileFlex>
  </>
)

const HubtypeHelps = props => {
  const {
    acf: { goal_title, goal_desc },
  } = aboutUsData(props.pageContext.lang)
  const { mockupES, mockupUSUK } = useStaticQuery(
    graphql`
      query {
        mockupES: file(relativePath: { eq: "about_us/MockupES.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        mockupUSUK: file(relativePath: { eq: "about_us/MockupUSUK.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  let mockup = null
  if (props.pageContext.lang == 'es_ES') {
    mockup = mockupES
  } else {
    mockup = mockupUSUK
  }

  return (
    <Section background={<Bg />} pt={[77, 60]} pb={[126, 98]}>
      <Flex width={[1, 1 / 2]} px={[15, 0]} flexDirection="column">
        <Title>{goal_title}</Title>
        <Text>{goal_desc}</Text>
        <Figures mockup={mockup} />
      </Flex>
    </Section>
  )
}

export default HubtypeHelps
