import React from 'react'
import Section from '../Section'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import StoryTriangle1 from '../../static/img/about_us/StoryTriangle1.svg'
import StoryTriangle2 from '../../static/img/about_us/StoryTriangle2.svg'
import StoryTriangleMobile from '../../static/img/about_us/StoryTriangleMobile.svg'
import Linkedin from '../../static/img/about_us/Linkedin.svg'
import { aboutUsData } from '../../hooks/about-us-data'

import { DesktopFlex, MobileFlex } from '../breakpoints'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #E5E5E5;
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;
  color: #333333;
`
const SecondaryText = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #696973;
`

const Text = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
`

const LinkedinName = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #ff2b5e;
`

const LinkedinLink = styled.a`
  cursor: pointer;
`

const LinkedinProfiles = props => (
  <Flex width={1}>
    <Flex width={1 / 2}>
      <LinkedinLink href="https://es.linkedin.com/in/marccaballemay">
        <Linkedin style={{ marginTop: 4, marginRight: 11, float: 'left' }} />
        <LinkedinName style={{ float: 'right' }}>Marc Caballé</LinkedinName>
      </LinkedinLink>
    </Flex>
    <Flex width={1 / 2}>
      <LinkedinLink href="https://es.linkedin.com/in/ericmarcospitarch">
        <Linkedin style={{ marginTop: 4, marginRight: 11, float: 'left' }} />
        <LinkedinName style={{ float: 'right' }}>Eric Marcos</LinkedinName>
      </LinkedinLink>
    </Flex>
  </Flex>
)

const Story = props => {
  const {
    acf: { story_title, story_desc, story_comment, story_image },
  } = aboutUsData(props.pageContext.lang)
  const { founders, foundersMobile } = useStaticQuery(
    graphql`
      query {
        founders: file(
          relativePath: { eq: "about_us/HubtypeFoundersPro.png" }
        ) {
          childImageSharp {
            fixed(width: 540, height: 360) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        foundersMobile: file(
          relativePath: { eq: "about_us/HubtypeFoundersPro.png" }
        ) {
          childImageSharp {
            fixed(width: 453, height: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <Section background={<Bg />} pt={[60, 80]} pb={[93, 74]}>
      <DesktopFlex>
        <StoryTriangle1 style={{ position: 'absolute', left: 0, top: 200 }} />
      </DesktopFlex>
      <MobileFlex>
        <StoryTriangle2
          style={{ position: 'absolute', left: 0, top: '87%', zIndex: 1 }}
        />
        <StoryTriangleMobile
          style={{ position: 'absolute', left: '50%', top: '95%', zIndex: 1 }}
        />
      </MobileFlex>
      <Flex px={[15, 0]} flexDirection={['column', 'row']}>
        <Flex width={[1, 1 / 2]}>
          <DesktopFlex>
            <Img fixed={founders.childImageSharp.fixed} />
            {/*<Img fixed={story_image.localFile.childImageSharp.fixed} /> */}
          </DesktopFlex>
        </Flex>
        <Flex px={[0, 30]} width={[1, 1 / 2]} flexDirection="column">
          <Title>{story_title}</Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: story_desc,
            }}
          />
          <SecondaryText
            dangerouslySetInnerHTML={{
              __html: story_comment,
            }}
          />
          <LinkedinProfiles />
          <MobileFlex>
            <Img fixed={foundersMobile.childImageSharp.fixed} />
          </MobileFlex>
        </Flex>
      </Flex>
    </Section>
  )
}

export default Story
