import React from 'react'
import { BreakpointProvider } from 'react-socks'

import ConversationalRevolution from '../components/about_us/conversational_revolution'
import Footer from '../components/footer'
import Head from '../components/head'
import Header from '../components/about_us/header'
import HubtypeHelps from '../components/about_us/hubtype_helps'
import Join from '../components/about_us/join'
import Navbar from '../components/Navbar'
import ProudOf from '../components/about_us/proud_of'
import Story from '../components/about_us/story'
import WhatsappDevelopers from '../components/about_us/whatsapp_developers'

import { aboutUsData } from '../hooks/about-us-data'
import '../styles/main.scss'

const AboutUs = props => {
  const {
    yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
  } = aboutUsData(props.pageContext.lang)

  return (
    <BreakpointProvider>
      <Head
        alternates={props.pageContext.i18n}
        autopilot={props.pageContext.autopilot}
        description={yoast_wpseo_metadesc}
        lang={props.pageContext.lang}
        title={yoast_wpseo_title}
        uri={props.pageContext.relativeUrl}
      />
      <Navbar
        theme="dark"
        style={{
          background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
        }}
        {...props}
      />
      <Header {...props} />
      <HubtypeHelps {...props} />
      <ConversationalRevolution {...props} />
      <Story {...props} />
      <WhatsappDevelopers {...props} />
      <ProudOf {...props} />
      <Join {...props} />
      <Footer style={{ marginTop: -52 }} displayBefore="none" {...props} />
    </BreakpointProvider>
  )
}
export default AboutUs
